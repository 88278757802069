//window.Vue = require('vue');
import Vue from 'vue'

import AlertBar from './components/AlertBar.vue';

Vue.component('alert-bar', AlertBar);

const alertapp = new Vue({
    el: '#alertApp'
});
