<template>
    <div class="alert-bar" ref="alertBarBody">
        <div :class="alertClass(alert)" v-for="alert in alerts">
            <button class="float-right btn" :data-alert-id="alert.id" @click.prevent="closeAlert">
                <i class="close-alert fa fa-times" :data-alert-id="alert.id"></i>
            </button>

            <div class="alert-message">
                <VueMarkdown>{{ alert.message}}</VueMarkdown>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import VueMarkdown from 'vue-markdown'
    export default {
        name: "AlertBar",
        props: [
            "alerts",
            "alertRoutes",
            "userId"
        ],
        components:{
            VueMarkdown
        },
        mounted() {
            this.initAlertBar()
        },
        methods: {
            initAlertBar(){
                if(!this.alerts.length){
                    return
                }

                //There are alerts, adjust the body from the top according to the alert bar height.
                this.shoveBody()
            },
            alertClass(alert){
                let alertClass = 'alert-item alert-item-' + alert.id + ' type-' + alert.type;
                if(alert.company_id){
                    alertClass += ' alert-company';
                }
                return alertClass;
            },
            shoveBody(){
                let alertBar = this.$refs.alertBarBody,
                    lastAlertItemHeight = !alertBar.clientHeight ? 0 : alertBar.clientHeight

                document.body.style.paddingTop = lastAlertItemHeight + 'px'
            },
            closeAlert(e){
                let alertId = e.target.dataset.alertId,
                    submissionData = {
                        alert_id: alertId,
                        user_id: this.userId
                    }
                axios.post(this.alertRoutes.dismiss, submissionData)
                    .then((response)=>{
                        // console.log(response)
                        this.removeAlertItem(alertId)
                            .then(()=>{
                                this.shoveBody()
                            })
                    })
                    .catch(()=>{
                        alert('There was a problem dismissing the alert. Please try again.');
                    })
            },
            removeAlertItem(alertId){
                return new Promise((resolve)=>{
                    let alertItemNode = document.querySelectorAll(`.alert-item-${alertId}`)

                    if(!alertItemNode.length){
                        return
                    }

                    alertItemNode[0].classList.add('fade-out')
                    // Wait for the fadeout animation before removing the item from the list.
                    setTimeout(()=>{
                        alertItemNode[0].remove()
                    }, 1000)

                    resolve()
                })

            }
        }
    }
</script>

<style  lang="scss">
    .alert-bar{
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 10;
        max-height: 167px;
        overflow: auto;
        .btn{
            padding: 0.775rem 0.75rem;
        }
        .alert-item {
            top: 0;
            width: 100%;
            background-color: yellow;
            border-bottom: #555500 solid 1px;
            transition: top 1s linear;
            font-size:90%;

            &.type-webinars{
                background-color: #755573;
                border-bottom: #553e54 solid 1px;
                p{
                    color:white;
                }
                .btn{
                    color:white;
                }
            }
            &.fade-out{
                top: -300px;
            }

            button{
                background-color: transparent;
                font-size: 1.25em;
            }

            .alert-message{
                padding: 1em 2.2em;
                margin-bottom: 0;

                p{
                    margin-bottom:0;
                }
            }

            &.alert-company{
                background:darkred;
                border-bottom: #510505 solid 1px;

                .alert-message{
                    p{
                        color: white;
                    }
                }
            }
        }

    }
</style>
